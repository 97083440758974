import _get from 'lodash/get';
import translations from './translations';

function readCookie(name) {
  let nameEQ = name + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

export function getLocale() {
  let locale = readCookie('locale-v1');
  return locale ? locale : 'ar_SA';
}

export function getLanguage() {
  return getLocale().split('_')[0];
}

export function getCountry() {
  return getLocale().split('_')[1];
}

export function translate(str) {
  let lang = getLanguage();
  return _get(translations, [lang, str], str);
}

export function track(event = 'pageview', eventData = {}) {
  try {
    eventData.pageType = 'login';

    window.dataLayer &&
      window.dataLayer.push({
        event,
        eventData,
      });
  } catch (err) {
    console.error('Something is wrong with GTM', err);
  }
}

export const PubSub = new (function() {
  let events = {};
  this.publish = function(name, el, data) {
    let elName = el && el !== name ? el + '.' + name : name;
    let handlers =
      events[elName] && events[elName].length === 0
        ? events[name]
        : events[elName];
    if (!handlers) return;
    handlers.forEach(function(handler) {
      handler.call(this, data);
    });
  };
  this.subscribe = function(name, el, handler) {
    if (el) name = el + '.' + name;
    let handlers = events[name];
    if (!handlers) {
      handlers = events[name] = [];
    }
    handlers.push(handler);
  };
  this.unsubscribe = function(name, el, handler) {
    if (el) name = el + '.' + name;
    let handlers = events[name];
    if (!handlers) return;

    let handlerIdx = handlers.indexOf(handler);
    handlers.splice(handlerIdx);
    if (handlers.length <= 0) {
      delete events[name];
    }
  };
  this.hasSubscribers = function(name, triggers) {
    if (!name && !triggers) return events;

    let triggerEl = triggers.find(trigger => events[trigger + '.' + name]);
    if (!triggerEl) {
      triggerEl = events[name] && events[name].length ? name : false;
    }
    return triggerEl;
  };
  this.destroy = function() {
    events = {};
  };
})();

export const userAuthEvents = {
  ready: 'ready',
  init: 'init',
  close: 'close',
  resize: 'resize',
  loginSuccess: 'loginSuccess',
  loginFail: 'loginFail',
  registerSuccess: 'registerSuccess',
  registerFail: 'registerFail',
  passwordResetSuccess: 'passwordResetSuccess',
  passwordResetFail: 'passwordResetFail',
  setHeading: 'setHeading',
  setMandatory: 'setMandatory',
};
