import { $ } from 'dollar-dom';
import { PubSub, userAuthEvents, track } from './utility';

export default class nmUserAuth {
  domainName = location.hostname
    .split('.')
    .slice(1)
    .join('.');
  ts = Date.now();
  iframeWrapperId = 'userAuthIframeWrapper';
  iframeId = 'userAuthIframe';
  iframeName = `userAuthIframe__${this.ts}`;
  loaderId = 'userAuthIframeLoader';
  userAuthDomain = `https://login.${this.domainName}`;
  iframeWrapperEl;
  userAuthIframeEl;
  userAuthLoader;
  userAuthReady = false;
  removeBGClickEvent;
  triggers = [];
  nmUserAuthConfig;
  element = null;
  attached = false;
  heading = '';
  centered = false;
  disableFadeIn = false;

  constructor({
    element = null,
    attachTo = '',
    heading = '',
    centered = false,
    disableFadeIn = false,
  } = {}) {
    if (!!nmUserAuth.instance) {
      return nmUserAuth.instance.nmUserAuthConfig;
    }

    this.attached = attachTo !== '';
    this.heading = heading;
    this.centered = centered;
    this.disableFadeIn = disableFadeIn;

    this.element = element;

    nmUserAuth.instance = this;

    document.domain = this.domainName;

    this.createCSS();
    this.createDOM(attachTo);
    if (!this.attached) {
      this.addLoader();
    }
    this.attachEvents();

    let nmUserAuthConfig = {
      on: (...args) => {
        let { eventName, el, fn } = this.getConfigArgs(args);
        PubSub.subscribe(eventName, el, fn);
      },
      off: (...args) => {
        let { eventName, el, fn } = this.getConfigArgs(args);
        PubSub.unsubscribe(eventName, el, fn);
      },
      showUserAuthModal: this.handleShowModalEvent,
      hideUserAuthModal: this.hideUserAuthModal,
      setHeading: this.setHeading,
      setMandatory: this.setMandatory,
      destroy: this.destroy,
      hasSubscribers: PubSub.hasSubscribers,
    };

    this.nmUserAuthConfig = nmUserAuthConfig;

    if (attachTo) {
      this.setMandatory(true);
      this.showUserAuthModal();
    }

    return this.nmUserAuthConfig;
  }

  createCSS = () => {
    let head = document.head || document.getElementsByTagName('head')[0];

    let style = $(`
      <style type="text/css">
        @-webkit-keyframes fader{
            0%{
                opacity:0 
            }
            100%{
                opacity:1 
            }
        }
        @keyframes fader{
            0%{
                opacity:0 
            }
            100%{
                opacity:1 
            }
        }
        @-webkit-keyframes scaler{
            0%,100%{
                -webkit-transform:scale(1);
                transform:scale(1) 
            }
            50%{
                -webkit-transform:scale(1.01);
                transform:scale(1.01) 
            }
        }
        @keyframes scaler{
            0%,100%{
                -webkit-transform:scale(1);
                transform:scale(1) 
            }
            50%{
                -webkit-transform:scale(1.01);
                transform:scale(1.01) 
            }
        }
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        @-webkit-keyframes spin {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
        .userAuth_iframe-wrapper{
            position: ${this.attached ? 'relative' : 'fixed'};
            top:15%;
            left:0;
            z-index:200;
            width:100%;
            height:100%;
            padding:${this.attached ? '0' : '15px'};
            display:none;
            -webkit-box-align:center;
            -ms-flex-align:center;
            align-items:center;
            -webkit-box-pack:center;
            -ms-flex-pack:center;
            -webkit-box-sizing:border-box;
            box-sizing:border-box;
        }
        .userAuth_iframe-wrapper.active{
            display: ${
              this.attached
                ? 'block;'
                : 'display:-webkit-box;display:-ms-flexbox;display:flex;'
            }
            flex-direction: column;
        }
        .userAuth_iframe-content{
            width:100%;
            max-width:${this.attached ? 'auto' : '500px'};
            overflow-y:auto;
            z-index:2;
            position:relative;
            overflow-x: hidden;
        }
        .userAuth_iframe{
            width:100%;
            height:100%;
            border:0;
            -webkit-animation:${
              this.disableFadeIn
                ? 'none'
                : 'fader .3s ease-out,scaler .3s ease-out'
            };
            animation:${
              this.disableFadeIn
                ? 'none'
                : 'fader .3s ease-out,scaler .3s ease-out'
            };
        }
        .userAuth_iframe-bg{
            position:fixed;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background:rgba(0,0,0,.5);
            z-index:1 
        }
        .userAuth_iframe-closeWrapper {
          width:100%;
          max-width:${this.attached ? 'auto' : '500px'};
          display: flex;
          justify-content: flex-end;
          position:relative;
          top:0;
        }
        .userAuth_iframe-close{
            font-size:34px;
            color:#aaa;
            text-decoration:none;
            position: absolute;
            z-index: 200;
            margin: 15px; 
        }
        #userAuthIframeLoader{
            display: none;
            position:fixed;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background:rgba(0,0,0,.5);
            z-index:300; 
        }
        .userAuth_iframe-loading {
            border-radius: 50%;
            width: 24px;
            height: 24px;
            border: .25rem solid rgba(177,177,177,.5);
            border-top-color: #51C0A9;
            animation: spin 1s infinite linear;
            border-style: double;
            border-width: .5rem;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom:0;
            margin: auto;
        }    
      </style>
    `);

    head.appendChild(style);
  };

  createDOM = attachTo => {
    let iframe = $(`
      <div id=${this.iframeWrapperId} class="userAuth_iframe-wrapper">
        ${!this.attached ? '<div class="userAuth_iframe-bg"></div>' : ''}
        <div class="userAuth_iframe-content">
          <div class="userAuth_iframe-closeWrapper"><a href="#" class="userAuth_iframe-close">&times;</a></div>
          <iframe src="${this.userAuthDomain}?ts=${this.ts}" id=${
      this.iframeId
    } class="userAuth_iframe" name=${this.iframeName}></iframe>
        </div>
      </div>
    `);

    if (this.attached) {
      let el = document.querySelector(attachTo);
      if (!el) {
        console.log(`Could not find element: ${attachTo}`);
        return;
      }
      el.appendChild(iframe);
    } else {
      document.body.appendChild(iframe);
    }

    // Update references
    this.iframeWrapperEl = document.getElementById(this.iframeWrapperId);
    this.userAuthIframeEl = document.getElementById(this.iframeId);
  };

  attachEvents = () => {
    $(`#${this.iframeId}`).on('load', this.iframeLoaded);
    this.removeBGClickEvent = $(`#${this.iframeWrapperId}`).on(
      'click',
      '.userAuth_iframe-bg',
      this.hideUserAuthModal
    );
    $(`#${this.iframeId}`).on(
      'click',
      '.userAuth_iframe-close',
      this.hideUserAuthModal
    );
    $(`#${this.loaderId}`).on('click', this.hideLoader);
    $('[data-user-auth]').on('click', this.handleShowModalBtnClick);
    window.addEventListener('message', this.messagesHandler);

    if (this.element) {
      $(this.element).on('click', this.handleShowModalBtnClick);
    }
  };

  iframeLoaded = e => {
    let iframeObj = this.userAuthIframeEl.contentWindow;

    // Signal the userAuth component that iframe is successfully loaded
    iframeObj.postMessage(
      {
        message: userAuthEvents.init,
        originDomain: this.domainName,
        isAttached: this.attached,
        heading: this.heading,
        isCentered: this.centered,
      },
      this.userAuthDomain
    );
  };

  handleShowModalBtnClick = e => {
    this.triggers = [];
    if (e) {
      e.preventDefault();
      if (e.target.id) this.triggers.push(e.target.id);
      let classes = Array.from(e.target.classList);
      this.triggers = [...this.triggers, ...classes];
    }
    this.showUserAuthModal();
  };

  handleShowModalEvent = trigger => {
    this.triggers = [];
    if (trigger) {
      this.triggers.push(trigger);
    }
    this.showUserAuthModal();
  };

  showUserAuthModal = () => {
    if (!this.userAuthReady) {
      this.showLoader();
    } else {
      this.iframeWrapperEl.classList.add('active');
      this.adjustIframeHeight();
      if (!this.attached) {
        document.body.style.overflow = 'hidden';
      }
      track();
    }
  };

  hideUserAuthModal = e => {
    if (e) {
      e.preventDefault();
    }

    this.iframeWrapperEl.classList.remove('active');
    if (!this.attached) {
      document.body.style.overflow = 'auto';
    }
  };

  messagesHandler = event => {
    if (event.origin !== this.userAuthDomain) return;

    if (
      typeof event.data === 'object' &&
      event.data &&
      'message' in event.data
    ) {
      switch (event.data.message) {
        case userAuthEvents.ready:
          this.onReady();
          break;
        case userAuthEvents.loginSuccess:
          this.onLoginSuccess(event.data.res, event.data.provider);
          break;
        case userAuthEvents.loginFail:
          this.onLoginFail(event.data.provider);
          break;
        case userAuthEvents.registerSuccess:
          this.onRegisterSuccess(event.data.redirectUrl, event.data.res);
          break;
        case userAuthEvents.registerFail:
          this.onRegisterFail();
          break;
        case userAuthEvents.passwordResetSuccess:
          this.onPWResetSuccess();
          break;
        case userAuthEvents.passwordResetFail:
          this.onPWResetFail();
          break;
        case userAuthEvents.close:
          this.hideUserAuthModal(event);
          break;
        case userAuthEvents.resize:
          this.adjustIframeHeight();
          break;
        default:
          break;
      }
    }
  };

  adjustIframeHeight = () => {
    if (!this.userAuthIframeEl.contentWindow) return;
    const bodyEl = this.userAuthIframeEl.contentWindow.document.body;
    const headerEl = bodyEl.querySelector('.userAuthHeading');
    const headerElHeight = headerEl ? headerEl.scrollHeight : 0;
    const socialBtns = bodyEl.querySelector('#uaSocialAuth');
    const socialBtnsHeight = socialBtns ? socialBtns.scrollHeight : 0;
    const activeTab = bodyEl.querySelector('.userAuth__animate--active');
    const activeTabHeight = activeTab ? activeTab.scrollHeight : 0;
    this.userAuthIframeEl.style.height =
      activeTabHeight + headerElHeight + socialBtnsHeight + 1 + 'px';
  };

  addLoader = () => {
    let loader = $(`
      <div id=${this.loaderId}>
        <div class="userAuth_iframe-loading"></div>
      </div>
    `);

    document.body.appendChild(loader);

    this.userAuthLoader = document.getElementById(this.loaderId);
  };

  removeLoader = () => {
    if (!this.userAuthLoader) return;
    this.userAuthLoader.remove();
  };

  showLoader = () => {
    if (!this.userAuthLoader) return;
    this.userAuthLoader.style.display = 'block';
  };

  hideLoader = () => {
    if (!this.userAuthLoader) return;
    this.userAuthLoader.style.display = 'none';
  };

  setHeading = heading => {
    if (!this.userAuthReady) {
      this.nmUserAuthConfig.on('ready', () => this.setHeading(heading));
      return;
    }

    let iframeObj = this.userAuthIframeEl.contentWindow;

    iframeObj.postMessage(
      {
        message: userAuthEvents.setHeading,
        heading,
        originDomain: this.domainName,
      },
      this.userAuthDomain
    );
  };

  setMandatory = isMandatory => {
    if (!this.userAuthReady) {
      this.nmUserAuthConfig.on('ready', () => this.setMandatory(isMandatory));
      return;
    }

    if (!this.userAuthIframeEl.contentWindow) return;

    let iframeObj = this.userAuthIframeEl.contentWindow;

    iframeObj.postMessage(
      {
        message: userAuthEvents.setMandatory,
        isMandatory,
        originDomain: this.domainName,
      },
      this.userAuthDomain
    );

    let closeModalBtn = $('.userAuth_iframe-close', this.iframeWrapperEl);

    if (isMandatory) {
      this.removeBGClickEvent();
      closeModalBtn.style.display = 'none';
    } else {
      this.removeBGClickEvent = $(`#${this.iframeWrapperId}`).on(
        'click',
        '.userAuth_iframe-bg',
        this.hideUserAuthModal
      );
      closeModalBtn.style.display = 'block';
    }
  };

  getConfigArgs = ([eventName, el, fn] = []) => {
    if (!fn) {
      fn = el;
      el = null;
    }
    return { eventName, el, fn };
  };

  onReady = () => {
    this.userAuthReady = true;

    if (this.attached || this.userAuthLoader.style.display === 'block') {
      this.handleShowModalEvent();
    }

    this.removeLoader();

    PubSub.publish(userAuthEvents.ready);
  };

  onLoginSuccess = (res = null, provider) => {
    track('tests', {
      testAction: `login_${provider}`,
      testResult: 'success',
    });

    let triggerEl = PubSub.hasSubscribers(
      userAuthEvents.loginSuccess,
      this.triggers
    );
    if (triggerEl) {
      PubSub.publish(userAuthEvents.loginSuccess, triggerEl, res);
      return;
    }

    this.hideUserAuthModal(event);
    window.location.href = location.href;
  };

  onLoginFail = provider => {
    track('tests', {
      testAction: `login_${provider}`,
      testResult: 'fail',
    });

    let triggerEl = PubSub.hasSubscribers(
      userAuthEvents.loginFail,
      this.triggers
    );
    if (triggerEl) {
      PubSub.publish(userAuthEvents.loginFail, triggerEl);
      return;
    }
  };

  onRegisterSuccess = (redirectUrl, res) => {
    track('tests', {
      testAction: 'register',
      testResult: 'success',
    });

    let triggerEl = PubSub.hasSubscribers(
      userAuthEvents.registerSuccess,
      this.triggers
    );
    if (triggerEl) {
      PubSub.publish(userAuthEvents.registerSuccess, triggerEl, res);
      return;
    }

    this.hideUserAuthModal(event);
    // the account page url is passed from the component that gets it from config
    window.location.href = redirectUrl;
  };

  onRegisterFail = () => {
    track('tests', {
      testAction: 'register',
      testResult: 'fail',
    });

    let triggerEl = PubSub.hasSubscribers(
      userAuthEvents.registerFail,
      this.triggers
    );

    if (triggerEl) {
      PubSub.publish(userAuthEvents.registerFail, triggerEl);
      return;
    }
  };

  onPWResetSuccess = () => {
    track('tests', {
      testAction: 'password_reset',
      testResult: 'success',
    });

    let triggerEl = PubSub.hasSubscribers(
      userAuthEvents.passwordResetSuccess,
      this.triggers
    );

    if (triggerEl) {
      PubSub.publish(userAuthEvents.passwordResetSuccess, triggerEl);
      return;
    }
  };

  onPWResetFail = () => {
    track('tests', {
      testAction: 'password_reset',
      testResult: 'fail',
    });

    let triggerEl = PubSub.hasSubscribers(
      userAuthEvents.passwordResetFail,
      this.triggers
    );

    if (triggerEl) {
      PubSub.publish(userAuthEvents.passwordResetFail, triggerEl);
      return;
    }
  };

  destroy = () => {
    delete nmUserAuth.instance;
    this.iframeWrapperEl.remove();
    window.removeEventListener('message', this.messagesHandler);
    PubSub.destroy();
  };
}

(function() {
  let userAuthEl = $('[data-user-auth]');

  if (userAuthEl.length) {
    window.nmUserAuthConfig = new nmUserAuth();
  }

  window.nmUserAuth = nmUserAuth;
})();
